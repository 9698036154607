<template>
  <q-card :class="cardCss">
    <q-card-section>
      <div class="text-h4 q-mb-sm text-primary">
        {{ getRes(title) }}
      </div>
      <slot />
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  name: "ProCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    mt: {
      type: Boolean,
      default: false,
    },
    ml: {
      type: Boolean,
      default: false,
    },
    mr: {
      type: Boolean,
      default: false,
    },
    mb: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cardCss() {
      let cssList = [];
      this.mt && cssList.push("q-mt-md");
      this.ml && cssList.push("q-ml-md");
      this.mr && cssList.push("q-mr-md");
      this.mb && cssList.push("q-mb-md");

      return cssList;
    },
  },
};
</script>
