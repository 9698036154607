<template>
  <div class="q-pa-md">
    <div class="text-h4 q-pl-md q-pb-sm text-primary">
      {{ getRes("CustMenu.Title.OpenDocumentList") }}
    </div>
    <pro-report
      title="Document.SearchDocument"
      :columns="columns"
      :value="documentList"
      row-key="id"
      auto-search
      selectable
      :loading="loading"
    >
      <template #selectedBody="{ row }">
        <pro-card ml title="Form.Section.DocumentNotice">
          <pro-key-value-table :rows="tableRows" :data="row" />
        </pro-card>

        <pro-card mt ml title="Form.Section.ApplyForInvitation">
          <div class="row q-col-gutter-sm">
            <div class="col-auto">
              <q-btn
                color="primary"
                :label="getRes('System.Button.NewSupplier')"
                no-caps
                unelevated
                @click="showGuestDialog(row)"
              />
            </div>
            <div class="col-auto">
              <q-btn
                color="primary"
                :label="getRes('System.Button.ExistingSupplier')"
                no-caps
                unelevated
                @click="showSupplierDialog(row)"
              />
            </div>
          </div>
        </pro-card>
      </template>
    </pro-report>
    <q-dialog v-model="supplier.isShowDialog" :content-style="'z-index: 1900'">
      <q-card style="width: 50%">
        <q-card-section class="q-gutter-md">
          <div class="row justify-between">
            <div class="col-auto text-h4 dialog-title">
              {{ getRes("CustMenu.Login.LoginSign") }}
            </div>
            <q-btn
              class="col-auto"
              icon="close"
              flat
              round
              dense
              v-close-popup
              no-caps
            />
          </div>
          <div>
            <span class="text-h5">{{
              getRes("CustMenu.Login.SupplierCode")
            }}</span>
            <q-input
              outlined
              v-model="supplier.userName"
              :placeholder="getRes('CustMenu.Login.SupplierCode')"
            />
          </div>
          <div>
            <span class="text-h5">
              {{ getRes("CustMenu.Login.Password") }}
            </span>
            <q-input
              outlined
              type="password"
              v-model="supplier.password"
              :placeholder="getRes('CustMenu.Login.Password')"
              autocomplete="off"
            />
          </div>
          <div>
            <q-btn
              no-caps
              color="primary"
              :label="getRes('CustMenu.Login.Login')"
              class="full-width"
              unelevated
              @click="loginSupplier"
              :disable="supplier.isDisable"
            />
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <new-registration-form-dialog
      v-model="isShowGuestDialog"
      :tender="selectedTender"
    />
  </div>
</template>

<script>
import ProReport from "@/components/PROSmart/Report/ProReport";
import ProKeyValueTable from "@/components/PROSmart/ProKeyValueTable";
import ProSmartLoginMixin from "@/views/mixins/ProSmartLogin";
import NewRegistrationFormDialog from "@/components/PROSmart/Guest/NewRegistrationFormDialog";
import ProCard from "@/components/PROSmart/ProCard.vue";

export default {
  components: {
    ProCard,
    ProReport,
    ProKeyValueTable,
    NewRegistrationFormDialog,
  },
  mixins: [ProSmartLoginMixin],
  data() {
    return {
      columns: [
        {
          name: "ref",
          label: this.getRes("Form.Field.DocRef"),
          style: "width:30px",
          field: "ref",
          align: "left",
          sortable: true,
          searchable: true,
          type: "string",
        },
        {
          name: "subjEng",
          label: this.getRes("Form.Field.Subject"),
          field: "subjEng",
          align: "left",
          sortable: true,
          searchable: true,
          type: "string",
        },
        {
          name: "issueDate",
          label: this.getRes("Form.Field.Issdate"),
          field: "issueDate",
          align: "left",
          sortable: true,
          searchable: true,
          type: "datetime",
          convertTo: "datetime",
        },
        {
          name: "closingDate",
          label: this.getRes("Form.Field.Clodate"),
          field: "closingDate",
          align: "left",
          sortable: true,
          searchable: true,
          type: "datetime",
          convertTo: "datetime",
        },
      ],
      documentList: [],
      tableRows: [
        {
          label: this.getRes("Form.Field.DocRef"),
          field: "ref",
        },
        {
          label: this.getRes("Form.Field.Subject"),
          field: "subjEng",
        },
        {
          label: this.getRes("Form.Field.Issdate"),
          field: "issueDate",
          format: this.$proSmart.common.getFormattedDate,
        },
        {
          label: this.getRes("Form.Field.Clodate"),
          field: "closingDate",
          format: this.$proSmart.common.getFormattedDate,
        },
        {
          label: this.getRes("Form.Field.Detail"),
          field: "details",
          style: "white-space: pre-wrap",
        },
        {
          label: this.getRes("Form.Field.ContactName"),
          field: "contactPersonName",
        },
        {
          label: this.getRes("Form.Field.ContactPhone"),
          field: "contactPersonTelNumber",
        },
      ],
      loading: true,
      selectedTender: null,
      supplier: {
        isShowDialog: false,
        userName: "",
        password: "",
        isDisable: false,
      },
      isShowGuestDialog: false,
      language: "English",
      SsoLogin: false,
    };
  },
  created() {
    this.$proSmart.guestUi.getOpenDocumentList(this).then((list) => {
      this.documentList = list;
      this.loading = false;
    });

    this.$proSmart.setting.getGuestPortalSetting(this).then((b) => {
      this.SsoLogin = b.enabledSupplierSsoLogin;
    });

    this.checkLanguage();
  },
  methods: {
    showSupplierDialog(tender) {
      if (this.SsoLogin) {
        this.$router.push({
          name: "SupplierLogin",
          params: { tender: tender, action: "OpenRegExistingSupplier" },
        });

        return;
      }

      this.selectedTender = tender;
      this.supplier.userName = "";
      this.supplier.password = "";
      this.supplier.isShowDialog = true;
    },
    showGuestDialog(tender) {
      this.selectedTender = tender;
      this.isShowGuestDialog = true;
    },
    loginSupplier() {
      this.supplier.isDisable = true;

      if (this.supplier.userName === "" && this.supplier.password === "") {
        this.alert(
          this.getRes("login.userisemptynotify"),
          this.getRes("notification")
        );

        this.supplier.isDisable = false;
        return;
      }

      this.$proSmart.login
        .loginByUserCodePwd(
          this,
          this.supplier.userName,
          window.sha512(window.sha512(this.supplier.password)).toLowerCase()
        )
        .then((b) => {
          if (!b.tokenId) {
            this.alert(b.info, this.getRes("notification"));
            this.supplier.isDisable = false;
            return;
          }

          const success = this.loginHcm(b, this.language);
          if (!success) {
            this.supplier.isDisable = false;
            return;
          }

          this.$proSmart.supplier
            .registerForOpenDocument(this, this.selectedTender.id)
            .then(() => {
              this.alert(
                this.$t("Form.Message.RegisterDocumentSuccess", {
                  ref: this.selectedTender.ref,
                }),
                this.getRes("notification")
              );
              this.$router.replace("/Dashboard");
            })
            .catch(() => {
              this.supplier.isDisable = false;
            });
        });
    },
    checkLanguage() {
      const Language = localStorage.getItem("Language");

      if (Language === "Chinese") {
        this.language = "Chinese";
      } else if (Language === "English") {
        this.language = "English";
      } else {
        this.language = "TraditionalChinese";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/quasar.variables";

.dialog-title {
  color: $primary;
}
</style>
