<template>
  <q-dialog
    :value="value"
    @input="$emit('input', $event)"
    :content-style="'z-index: 1900'"
  >
    <q-card style="width: 50%">
      <q-card-section>
        <div class="row justify-between">
          <div class="col-auto text-h4 dialog-header">
            {{ getRes("Form.Option.RegisterForm") }}
          </div>
          <q-btn
            class="col-auto"
            icon="close"
            flat
            round
            dense
            v-close-popup
            no-caps
          />
        </div>
        <q-form greedy class="q-gutter-sm q-pt-sm" @submit="sendOtp">
          <div>
            <span class="text-h5">{{
              getRes("Form.Field.CompanyNameEnglish")
            }}</span>
            <q-input
              outlined
              v-model="cmpyNameEng"
              :placeholder="getRes('Form.Field.CompanyNameEnglish')"
              lazy-rules
              :rules="[
                (val) =>
                  (val && val.length > 0) || getRes('Form.Validation.Empty'),
              ]"
              :disable="isLoading || isRequestedOtp"
            />
          </div>
          <div>
            <span class="text-h5">
              {{ getRes("Form.Field.CompanyNameChinese") }}
            </span>
            <q-input
              outlined
              v-model="cmpyNameChi"
              :placeholder="getRes('Form.Field.CompanyNameChinese')"
              lazy-rules=""
              :rules="[
                (val) =>
                  (val && val.length > 0) || getRes('Form.Validation.Empty'),
              ]"
              :disable="isLoading || isRequestedOtp"
            />
          </div>
          <div>
            <span class="text-h5">
              {{ getRes("Form.Field.ContactEmail") }}
            </span>
            <q-input
              outlined
              v-model="emailAddress"
              :placeholder="getRes('Form.Field.ContactEmail')"
              lazy-rules
              :rules="[
                (val) =>
                  (val && val.length > 0) || getRes('Form.Validation.Empty'),
                (val) =>
                  emailRegex.test(val) ||
                  getRes('Form.Validation.InvalidEmailAddress'),
              ]"
              :disable="isLoading || isRequestedOtp"
            />
          </div>
          <div v-show="!isRequestedOtp">
            <q-btn
              no-caps
              color="primary"
              :label="getRes('System.Button.RequestVerificationCode')"
              class="full-width"
              unelevated
              type="submit"
              :disable="isLoading"
            />
          </div>
        </q-form>
        <q-form
          class="q-gutter-sm q-pt-sm"
          @submit="submitForm"
          v-show="isRequestedOtp"
        >
          <div>
            <span class="text-h5">
              {{ getRes("Form.Field.VerificationCode") }}
            </span>
            <q-input
              outlined
              v-model="otpCode"
              :placeholder="getRes('Form.Field.VerificationCode')"
              lazy-rules
              :rules="[
                (val) =>
                  (val && val.length > 0) ||
                  getRes('Form.Message.Error.VerificationCode'),
              ]"
              :disable="isLoading"
            >
              <template v-slot:before> {{ prefix }} - </template>
            </q-input>
          </div>
          <div>
            <q-btn
              no-caps
              color="primary"
              :label="getRes('System.Button.Submit')"
              class="full-width"
              unelevated
              type="submit"
              :disable="isLoading"
            />
          </div>
          <div>
            <q-btn
              no-caps
              outline
              color="primary"
              :label="
                timeNum > 0
                  ? `${getRes('Form.Field.ResendCode')} (${timeNum})`
                  : `${getRes('Form.Field.ResendCode')}`
              "
              class="full-width"
              unelevated
              @click="sendOtp"
              :disable="isLoading || !enableResend"
            />
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: "NewRegistrationFormDialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    tender: {
      default: () => ({}),
    },
  },
  data() {
    return {
      cmpyNameEng: "",
      cmpyNameChi: "",
      emailAddress: "",
      prefix: "",
      otpCode: "",
      timeNum: 0,
      enableResend: true,
      resendInterval: null,
      isLoading: false,
      isRequestedOtp: false,
      resendTime: 60,
      emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    };
  },
  methods: {
    sendOtp() {
      this.isLoading = true;
      this.enableResend = false;

      this.$proSmart.guestRegister
        .openRegisterSendOtpCode(
          this,
          this.tender.id,
          this.cmpyNameEng,
          this.cmpyNameChi,
          this.emailAddress
        )
        .then((rs) => {
          if (rs.success === true) {
            this.prefix = rs.prefix;
            this.isRequestedOtp = true;
            this.timeNum = this.resendTime;
            this.resendInterval = setInterval(this.updateResendTime, 1000);
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.enableResend = this.timeNum === 0;
        });
    },
    submitForm() {
      this.isLoading = true;

      this.$proSmart.guestRegister
        .openRegisterCheckOtpAndAddToDocument(
          this,
          this.tender.id,
          this.cmpyNameEng,
          this.cmpyNameChi,
          this.emailAddress,
          this.otpCode.trim()
        )
        .then((rs) => {
          if (rs.success) {
            this.alert(
              this.$t("Form.Message.RegisterDocumentSuccess", {
                ref: this.tender.ref,
              }),
              this.$t("notification")
            );
            this.$emit("input", false);
          }
        })
        .finally(() => (this.isLoading = false));
    },
    updateResendTime() {
      this.timeNum--;
      if (this.timeNum <= 0) {
        this.enableResend = true;
        clearInterval(this.resendInterval);
      }
    },
  },
  watch: {
    value: {
      handler(newVal) {
        if (newVal) {
          this.cmpyNameEng = "";
          this.cmpyNameChi = "";
          this.emailAddress = "";
          this.prefix = "";
          this.otpCode = "";
          this.enableResend = false;
          this.resendInterval = null;
          this.isLoading = false;
          this.isRequestedOtp = false;
        } else {
          clearInterval(this.resendInterval);
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/quasar.variables";
.dialog-header {
  color: $primary;
}
</style>
