var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-dialog',{attrs:{"value":_vm.value,"content-style":'z-index: 1900'},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c('q-card',{staticStyle:{"width":"50%"}},[_c('q-card-section',[_c('div',{staticClass:"row justify-between"},[_c('div',{staticClass:"col-auto text-h4 dialog-header"},[_vm._v(" "+_vm._s(_vm.getRes("Form.Option.RegisterForm"))+" ")]),_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"col-auto",attrs:{"icon":"close","flat":"","round":"","dense":"","no-caps":""}})],1),_c('q-form',{staticClass:"q-gutter-sm q-pt-sm",attrs:{"greedy":""},on:{"submit":_vm.sendOtp}},[_c('div',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.getRes("Form.Field.CompanyNameEnglish")))]),_c('q-input',{attrs:{"outlined":"","placeholder":_vm.getRes('Form.Field.CompanyNameEnglish'),"lazy-rules":"","rules":[
              function (val) { return (val && val.length > 0) || _vm.getRes('Form.Validation.Empty'); } ],"disable":_vm.isLoading || _vm.isRequestedOtp},model:{value:(_vm.cmpyNameEng),callback:function ($$v) {_vm.cmpyNameEng=$$v},expression:"cmpyNameEng"}})],1),_c('div',[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.getRes("Form.Field.CompanyNameChinese"))+" ")]),_c('q-input',{attrs:{"outlined":"","placeholder":_vm.getRes('Form.Field.CompanyNameChinese'),"lazy-rules":"","rules":[
              function (val) { return (val && val.length > 0) || _vm.getRes('Form.Validation.Empty'); } ],"disable":_vm.isLoading || _vm.isRequestedOtp},model:{value:(_vm.cmpyNameChi),callback:function ($$v) {_vm.cmpyNameChi=$$v},expression:"cmpyNameChi"}})],1),_c('div',[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.getRes("Form.Field.ContactEmail"))+" ")]),_c('q-input',{attrs:{"outlined":"","placeholder":_vm.getRes('Form.Field.ContactEmail'),"lazy-rules":"","rules":[
              function (val) { return (val && val.length > 0) || _vm.getRes('Form.Validation.Empty'); },
              function (val) { return _vm.emailRegex.test(val) ||
                _vm.getRes('Form.Validation.InvalidEmailAddress'); } ],"disable":_vm.isLoading || _vm.isRequestedOtp},model:{value:(_vm.emailAddress),callback:function ($$v) {_vm.emailAddress=$$v},expression:"emailAddress"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isRequestedOtp),expression:"!isRequestedOtp"}]},[_c('q-btn',{staticClass:"full-width",attrs:{"no-caps":"","color":"primary","label":_vm.getRes('System.Button.RequestVerificationCode'),"unelevated":"","type":"submit","disable":_vm.isLoading}})],1)]),_c('q-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRequestedOtp),expression:"isRequestedOtp"}],staticClass:"q-gutter-sm q-pt-sm",on:{"submit":_vm.submitForm}},[_c('div',[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.getRes("Form.Field.VerificationCode"))+" ")]),_c('q-input',{attrs:{"outlined":"","placeholder":_vm.getRes('Form.Field.VerificationCode'),"lazy-rules":"","rules":[
              function (val) { return (val && val.length > 0) ||
                _vm.getRes('Form.Message.Error.VerificationCode'); } ],"disable":_vm.isLoading},scopedSlots:_vm._u([{key:"before",fn:function(){return [_vm._v(" "+_vm._s(_vm.prefix)+" - ")]},proxy:true}]),model:{value:(_vm.otpCode),callback:function ($$v) {_vm.otpCode=$$v},expression:"otpCode"}})],1),_c('div',[_c('q-btn',{staticClass:"full-width",attrs:{"no-caps":"","color":"primary","label":_vm.getRes('System.Button.Submit'),"unelevated":"","type":"submit","disable":_vm.isLoading}})],1),_c('div',[_c('q-btn',{staticClass:"full-width",attrs:{"no-caps":"","outline":"","color":"primary","label":_vm.timeNum > 0
                ? ((_vm.getRes('Form.Field.ResendCode')) + " (" + _vm.timeNum + ")")
                : ("" + (_vm.getRes('Form.Field.ResendCode'))),"unelevated":"","disable":_vm.isLoading || !_vm.enableResend},on:{"click":_vm.sendOtp}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }